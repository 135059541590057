import {LoaderWithFetch} from "../components/Loader";
import {BlogEntries, BlogOverviewEntry} from "../dao/Blog";
import {Helmet} from "react-helmet-async";
import React from "react";


export default function BlogListPage() {
    const url = "https://codebuddy.ca/blog"
    const title = "Codebuddy: Learn to write code with AI"
    const description = "Learn how to code with AI, or accelerate your skills with improved AI coding tricks and techniques."
    return (
        <>
            <Helmet prioritizeSeoTags>
                <link rel="canonical" key="link-canonical" href={url}/>
                <title>{title}</title>
                <meta property="og:title" name="og:title" key="og:title" content={title}/>
                <meta property="twitter:title" name="twitter:title" key="twitter:title" content={title}/>

                <meta property="description" name="description" key="description" content={description}/>
                <meta property="og:description" name="og:description" key="og:description" content={description}/>
                <meta property="twitter:description" name="twitter:description" key="twitter:description" content={description}/>
                <meta property="og:url" name="og:url" key="og:url" content={url}/>
            </Helmet>
            <LoaderWithFetch<BlogEntries> url={"/api/v1/blogList"}>
                {response => {
                    return (
                        <div className="container">
                            <div className="blog-entries-container">
                                <h1 className="text-center mb-4">Latest Blog Entries</h1>
                                {
                                    response.entries.length === 0 && (
                                        <div className="text-center">No blog entries found</div>
                                    )
                                }
                                <div className={"blog-entries"}>
                                    {
                                        response.entries.map(entry => {
                                            return (
                                                <BlogListEntry entry={entry} key={entry.url}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }}
            </LoaderWithFetch>
        </>
    )
}

export function BlogListEntry(props: { entry: BlogOverviewEntry }) {
    const entry = props.entry;
    return (
        <a className="blog-entry"
           style={{ // the (23, 30, 37) comes from --var(--neutral-800)
               backgroundImage: "linear-gradient(to right, rgba(23, 30, 37, 0), rgba(23, 30, 37, 1)), url('" + entry.ogImageUrl + "')"
           }}
           href={`/blog/${entry.url}`}
           key={entry.url}
        >
            <h3 className="blog-title">
                {entry.shortTitle}
            </h3>
            {
                entry.timestamp && `Published on ${new Date(entry.timestamp).toDateString()}`
            }
        </a>
    )
}