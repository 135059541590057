import includeOpenFiles from "../images/videos/IncludeOpenFiles.webp";
import fullMultiFileCreateAndEdit from "../images/videos/FULL-MultiFileDemonstration1-1200p.webp";
import codebaseUnderstanding from "../images/videos/CodebaseUnderstanding.webp";
import fullCodebaseUnderstanding from "../images/videos/FULL-CodebaseUnderstanding1-1200p.webp";
import multiFileCreateAndEdit from "../images/videos/MultiFileCreateAndEdit.webp";
import urlInclusions from "../images/videos/UrlInclusions.webp";
import fullUrlInclusions from "../images/videos/FULL-UrlInclusions1-1200p.webp";
import fullDuplexVoiceGraphic from "../images/frontpage/full-duplex-voice-graphic1.png";
import vscodeAndJetbrains from "../images/frontpage/vscode-and-jetbrains-1.jpg";
import {ImageBoxAltFullscreen, VideoBox} from "./Videobox";
import {BgShape003} from "./BgShapes";
import React from "react";
import "./Features.css"

class CodebuddyFeature {
    title: string;
    description: string;
    resource: string;
    isImage: boolean;
    fullSizeResource?: string;

    constructor(title: string, description: string, resource: string, isImage: boolean, fullSizeResource?: string) {
        this.title = title;
        this.description = description;
        this.resource = resource;
        this.isImage = isImage;
        this.fullSizeResource = fullSizeResource;
    }
}

const features: CodebuddyFeature[] = [
    new CodebuddyFeature("Select Files For Your Prompt", "Automatically include all files that you have open in your editor in your next prompt with up to 128,000 tokens in AI context memory size. Let the AI code. You approve the multi-file patch, a part of it, or request any necessary changes.", includeOpenFiles, true, fullMultiFileCreateAndEdit),
    new CodebuddyFeature("Deep Codebase Understanding", "Codebuddy can scan your entire repository and generate a vector database from it. This allows Codebuddy to select files for you, or answer questions about your codebase if you're not familiar with it. This is an AI coding assistant that deeply understands your repository.", codebaseUnderstanding, true, fullCodebaseUnderstanding),
    new CodebuddyFeature("Multi-file Code Apply", "Generate new files or change multiple existing files with a single prompt. Codebuddy will insert code automatically for you in the form of a familiar unified patch (diff). Take your AI coding to the next level with industry-leading multi-file support.", multiFileCreateAndEdit, true, fullMultiFileCreateAndEdit),
    new CodebuddyFeature("Use Web Documentation or Blogs as References", "Install the Codebuddy Chrome extension for the new 'Send to Codebuddy' feature that is added to the context menu when you right-click on a webspage. This will allow you to include documentation from the web and let the Codebuddy AI implement it for you.", urlInclusions, true, fullUrlInclusions),
    new CodebuddyFeature("Full-Duplex Voice Conversation", "Talk to your AI coder assistant using voice input to speak your changes into existence and optionally let the AI talk to you with TTS (text-to-speech) powered by the most powerful TTS available from ElevenLabs.", fullDuplexVoiceGraphic, true),
    new CodebuddyFeature("Plugins for VSCode and Jetbrains", "Work directly in your favorite IDE to maximize efficiency and convenience! Our growing community continues to claim that this AI code assistant is the best available for the Jetbrains ecosystem, and even better than Cursor (the industry leading VSCode clone).", vscodeAndJetbrains, true)
]

function CodebuddyStepComponent(props: { step: CodebuddyFeature, index: number }) {
    return (
        <div className={`timeline-chapter fade-in-on-scroll ${props.index % 2 === 0 ? "reverse" : ""}`}>
            <div className="timeline-chapter-image rounded-xs">
                {props.step.isImage ? (
                    <ImageBoxAltFullscreen resource={props.step.resource} fullSizeResource={props.step.fullSizeResource} className="timeline-image-box"/>
                ) : (
                    <VideoBox videoSrc={props.step.resource} fullSizeVideoSrc={props.step.fullSizeResource}/>
                )}
            </div>
            <div className="timeline-chapter-indicator fade-in-on-scroll"></div>
            <div className="timeline-chapter-item">
                <div className="title-sm">
                    <strong>{props.step.title}</strong>
                </div>
                <div>{props.step.description}
                </div>
            </div>
        </div>
    )

}

export function FrontpageFeatures() {
    return (
        <section>
            <div className="overflow-hidden">
                <div className="section bg-neutral-800 curve-bottom">
                    <div className="container-xl">
                        <div className="bg-pattern-wrap centered"><BgShape003/></div>
                        <div className="margin-top margin-9xl">
                            <div className="content-centered">
                                <h2 className="title-md _20ch">What is it like to generate code with Codebuddy?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="steps-container container-lg">
                    <div className="w-layout-grid timeline">
                        {
                            features.map((step, index) => (
                                <CodebuddyStepComponent key={index} step={step} index={index}/>
                            ))
                        }
                        <div className="timeline-way-bg">
                            <div className="timeline-way" style={{height: "100%"}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}