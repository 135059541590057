import React, {useEffect, useState} from "react";
import jetBeansLogo from "../images/jb_beam.svg";
import vscodeLogo from "../images/vscode.svg";
import "yet-another-react-lightbox/styles.css";
import {NotifyPopup} from "../components/Popups";
import {PlansTable} from "./PlansPage";
import './Frontpage.css';
import {FrontpageFeatures} from "../components/Features";
import {FrontpageTestimonials} from "../components/Testimonials";

export default function Frontpage() {
    return (
        <>
            <FrontpageHead/>
            <FrontpageFeatures/>
            <FrontpageTestimonials/>
            <FrontpagePricing/>
        </>
    )
}

function isMobile(): boolean {
    return window.matchMedia("(max-width: 600px)").matches;
}

function FrontpageHead() {
    const options = ["JAVA", "JS", "KOTLIN", "C++", "C#", "PYTHON", "PHP", "RUBY", "GO", "RUST", "TS", "HTML", "CSS", "SQL", "BASH", "MD", "LATEX", "SCSS", "LESS", "VUE", "SVELTE", "ANGULAR", "REACT", "EMBER", "ZIG"];
    const [currentOption, setCurrentOption] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentOption((prevOption) => (prevOption + 1) % options.length);
        }, 800);
        return () => clearInterval(interval);
    }, [options.length]);


    return (
        <div className="section gradient__01">
            <div className="margin-top margin-9xl">
                <div className="container-lg">
                    <div className="w-layout-grid hero-grid-halves">
                        <div className="content-centered-tablet">
                            <div className="margin-bottom margin-3xl">
                                <div className="margin-bottom margin-2xl">
                                    <h3 className="title-md">AI Powered Coding<br/>
                                        <span className="text-gradient__indigo-red">Perfect for </span>
                                        <span
                                            className={`text-gradient__indigo-red perfect-for-anim`}>{options[currentOption]}</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="w-layout-grid column-base">
                                <div className="w-layout-grid row-base column-tablet">
                                    <div>Chat about your codebase and let your AI code assistant <br/>
                                        update the multiple files right in your favorite IDE!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="max-width-xl content-left">
                                <div style={{paddingTop: "56.17021276595745%"}}
                                     className="w-embed-youtubevideo">
                                    <MainYoutubeVideo/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-blockcontainer container w-container">
                        <JetbrainsButton/>
                        <VSCodeButton/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function JetbrainsButton() {
    return (
        <a href="https://plugins.jetbrains.com/plugin/22666-codebuddy"
           className="button-animated large w-inline-block download-button">
            <img src={jetBeansLogo} loading="lazy" width="50" alt="Jetbrains Icon"
                 className="image-11"/>
            <div className="button-label">Jetbrains Plugin</div>
            <div className="button-hover-background"></div>
        </a>
    )
}

function MainYoutubeVideo() {
    const [open, setOpen] = useState(false);
    if (open || !isMobile()) {
        return (
            <iframe
                src="https://www.youtube-nocookie.com/embed/bdULrd276LY?rel=0&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;start=0"
                style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "auto",
                    border: "none"
                }} allow="autoplay; encrypted-media" allowFullScreen={true}
                title="Codebuddy Intro and Birthday Feature Demonstrator"></iframe>
        )
    } else {
        return (
            <img className="main-youtube-thumbnail" src="/images/codebuddy-video-play-image.jpg" alt="Codebuddy Overview" onClick={() => setOpen(true)}/>
        )
    }
}

export function VSCodeButton() {
    const [open, setOpen] = useState(false)
    return (
        <>
            <button className="button-animated large w-inline-block download-button" onClick={() => setOpen(true)}>
                <img className="image-12" src={vscodeLogo} loading="lazy" width="50" alt="VSCode Icon"/>
                <div className="button-label">VSCode Extension</div>
                <div className="button-hover-background"></div>
            </button>
            <NotifyPopup open={open} setOpen={setOpen}>
                Private beta testing for the Visual Studio Code plugin has begun.
                If you're interested in participating, please let us know in the #general channel on the &nbsp;
                <a href="https://discord.gg/Qa9vYdE78a" className={"link-light"}><i
                    className={"fab fa-discord"}/> Codebuddy Discord server</a>.
            </NotifyPopup>
        </>
    )

}

function FrontpagePricing() {
    return (
        <section id="pricing" className="section padding-bottom">
            <PlansTable includeUserData={false}/>
        </section>
    )
}
